import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "comboTree" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_popover, _mergeProps({
      ref: el=>_ctx.refMap.set('popoverRef',el),
      "popper-class": "myComboTreeClass",
      trigger: "click",
      width: _ctx.otherParams.popoverWidth,
      disabled: _ctx.disabled,
      onHide: _ctx.hide,
      onAfterEnter: _ctx.show
    }, _ctx.comboParam.popoverAttrs), {
      reference: _withCtx(() => [
        _createVNode(_component_el_input, _mergeProps({
          ref: el=>_ctx.refMap.set('input',el),
          readonly: _ctx.readOnly,
          modelValue: _ctx.comboText,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.comboText) = $event)),
          placeholder: _ctx.$t('combo.placeholder'),
          disabled: _ctx.disabled,
          clearable: "",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible = !_ctx.visible)),
          onInput: _ctx.inputChange,
          onClear: _ctx.inputClear
        }, _ctx.comboParam.inputAttrs), {
          append: _withCtx(() => [
            _createElementVNode("i", {
              class: "iconfont icon-a-ziyuan30",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = !_ctx.visible))
            })
          ]),
          _: 1
        }, 16, ["readonly", "modelValue", "placeholder", "disabled", "onInput", "onClear"])
      ]),
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_el_tree, _mergeProps({
          data: _ctx.data,
          props: _ctx.defaultProps,
          "node-key": "value",
          ref: el=>_ctx.refMap.set('comboTreeRef',el),
          "filter-node-method": _ctx.filterNode,
          "show-checkbox": _ctx.baseParams.showCheckbox,
          "check-on-click-node": _ctx.baseParams.checkOnClick,
          "default-expand-all": _ctx.baseParams.expandAll,
          "default-expanded-keys": _ctx.baseParams.expandedKeys,
          onNodeClick: _ctx.handleNodeClick,
          onCheckChange: _ctx.checkChange
        }, _ctx.comboParam.treeAttrs), null, 16, ["data", "props", "filter-node-method", "show-checkbox", "check-on-click-node", "default-expand-all", "default-expanded-keys", "onNodeClick", "onCheckChange"]), [
          [_directive_loading, _ctx.loading]
        ])
      ]),
      _: 1
    }, 16, ["width", "disabled", "onHide", "onAfterEnter"])
  ]))
}